import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import axios from 'axios';

const Dashboard = () => {
  const [products, setProducts] = useState([]); // สถานะสำหรับเก็บข้อมูลสินค้า
  const [categories, setCategories] = useState([]); // สถานะสำหรับเก็บหมวดหมู่
  const [selectedCategory, setSelectedCategory] = useState(''); // สถานะสำหรับหมวดหมู่ที่เลือก
  const [searchQuery, setSearchQuery] = useState(''); // สถานะสำหรับคำค้นหา
  const [showModal, setShowModal] = useState(false); // สถานะสำหรับการแสดง Modal
  const [selectedProduct, setSelectedProduct] = useState(null); // สถานะสำหรับเก็บสินค้าที่เลือก
  const [likedProducts, setLikedProducts] = useState([]); // สถานะสำหรับเก็บสินค้าที่ถูกใจ
  // eslint-disable-next-line no-unused-vars
const [likedCategories, setLikedCategories] = useState({});
  const [isExpanded, setIsExpanded] = useState(false); // สถานะการขยายภาพ
 

  useEffect(() => {
    axios.get('https://editor.dp.co.th/api/products')
      .then(response => {
        const productsData = response.data;
        setProducts(productsData);
  
        // จัดกลุ่มหมวดหมู่ตามชื่อ (ไม่ต้องแสดงเลขนำหน้า)
        const uniqueCategories = productsData.reduce((acc, product) => {
          const category = product.category;
          if (typeof category === 'string' && category.startsWith('0')) {
            const categoryName = category.substring(3); // ดึงชื่อหมวดหมู่ (ตัดเลขนำหน้า 3 ตัว)
            if (!acc.includes(categoryName)) { // ตรวจสอบว่ามีชื่อหมวดหมู่ใน Array แล้วหรือยัง
              acc.push(categoryName);
            }
          } else {
            // ถ้าไม่มีเลขนำหน้า ให้เพิ่มเข้ากลุ่ม "อื่นๆ" (ถ้ายังไม่มี)
            if (!acc.includes('อื่นๆ')) {
              acc.push('อื่นๆ');
            }
          }
          return acc;
        }, []);
  
        setCategories(uniqueCategories);
      })
      .catch(error => console.log('Error fetching products:', error));
  }, []);
  
  const filteredProducts = selectedCategory === 'liked' ?
    likedProducts.map(productId => products.find(product => product._id === productId)).filter(Boolean) :
    selectedCategory ?
      products.filter(product => {
        return selectedCategory.split('|').some(category => product.category.includes(category));
      }) :
      products;

  const searchedProducts = searchQuery ? 
    filteredProducts.filter(product => 
      product.name.toLowerCase().includes(searchQuery.toLowerCase()) || 
      product.coating.toLowerCase().includes(searchQuery.toLowerCase()) ||
      product.material.toLowerCase().includes(searchQuery.toLowerCase())
    ) : 
    filteredProducts;

  const handleShowModal = (product) => {
    setSelectedProduct(product);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleLike = (productId) => {
    setLikedProducts((prevLikedProducts) => {
      let updatedLikedProducts;

      if (prevLikedProducts.includes(productId)) {
        updatedLikedProducts = prevLikedProducts.filter(id => id !== productId);
      } else {
        updatedLikedProducts = [...prevLikedProducts, productId];
      }

      localStorage.setItem('likedProducts', JSON.stringify(updatedLikedProducts));

      // อัปเดตหมวดหมู่ที่ถูกใจ
      const updatedLikedCategories = updatedLikedProducts.reduce((acc, productId) => {
        const product = products.find(p => p._id === productId);
        if (product) {
          const category = product.category;
          if (!acc[category]) {
            acc[category] = [];
          }
          acc[category].push(product);
        }
        return acc;
      }, {});
      setLikedCategories(updatedLikedCategories);

      return updatedLikedProducts;
    });
  };

  const handleImageClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Container fluid>
    <Row style={{ marginTop: '80px' }}>
      <Col
        xs={12}
        className="d-flex align-items-center justify-content-center"
        style={{
          backgroundImage: 'url("/nav3.jpg")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: '350px',
        }}
      >
        <h1 className="text-white" style={{ textShadow: '2px 2px 4px rgba(0,0,0,0.6)' }}>
          สินค้าของเรา
        </h1>
      </Col>
    </Row>
  
    <Row className="mt-3">
      <Col xs={12} className="d-flex justify-content-start mb-4">
        <input
          type="text"
          className="form-control shadow-sm"
          placeholder="ค้นหาได้ที่นี่..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{
            width: '227px',
            maxWidth: '227px',
            borderRadius: '5px',
            border: '3px solid #ff6600',
          }}
        />
      </Col>
  
      <Col xs={2} className="d-flex flex-column align-items-start" style={{ paddingTop: '0px' }}>
  {/* ปุ่ม "ทั้งหมด" */}
  <div
    className="mb-0"
    style={{
      backgroundColor: selectedCategory === '' ? '#FF8C00' : '#f1f1f1',
      width: '100%',
      borderRadius: '5px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      padding: '15px',
      cursor: 'pointer',
      color: selectedCategory === '' ? '#fff' : '#000',
      border: '2px solid #FFDEAD',
    }}
    onClick={() => setSelectedCategory('')}
  >
    <h4 style={{ fontSize: '14px', textAlign: 'center' }}>ทั้งหมด</h4>
  </div>

  {/* หมวดหมู่ "ถูกใจ" */}
  <div
    className="mb-0"
    style={{
      backgroundColor: selectedCategory === 'liked' ? '#FF8C00' : '#f1f1f1',
      width: '100%',
      borderRadius: '5px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      padding: '15px',
      cursor: 'pointer',
      color: selectedCategory === 'liked' ? '#fff' : '#000',
      transition: 'background-color 0.3s ease, color 0.3s ease',
      border: '2px solid #FFDEAD',
    }}
    onClick={() => setSelectedCategory('liked')}
  >
    <h4 style={{ fontSize: '1rem', textAlign: 'center' }}>ถูกใจ</h4>
  </div>

  {categories.map((categoryName, index) => (
  <div
    key={index}
    className="mb-0"
    style={{
      backgroundColor: selectedCategory === categoryName ? '#FF8C00' : '#f1f1f1',
      width: '100%',
      borderRadius: '5px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      padding: '15px',
      cursor: 'pointer',
      color: selectedCategory === categoryName ? '#fff' : '#000',
      transition: 'background-color 0.3s ease, color 0.3s ease',
      border: '2px solid #FFDEAD',
    }}
    onClick={() => setSelectedCategory(categoryName)}
  >
    <h4 style={{ fontSize: '1rem', textAlign: 'center' }}>{categoryName}</h4> {/* แสดงชื่อหมวดหมู่ */}
  </div>
))}
</Col>
        <Col xs={10} className="d-flex justify-content-center align-items-start">
          <div
            className="p-0"
            style={{
              backgroundColor: '#FFA500',
              width: '100%',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              minHeight: '0px',
              paddingTop: '5px',
              border: '2px solid #ffb84d',
              borderRadius: '5px',
              position: 'relative',
              overflow: 'hidden', // ป้องกันข้อมูลล้น
            }}
          >
           <div
  style={{
    position: 'absolute',
    top: '0px',
    left: 0,
    right: 0,
    backgroundColor: '#FF8C00',
    padding: '10px 10px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    border: '2px solid #FF8C00',
    fontSize: '18px',
    fontWeight: 'bold',
    textAlign: 'left',
    color: '#ffffff',
    zIndex: 1, // ทำให้แถบนี้อยู่บนสุด
  }}
>
<img
    src="/icon1.png"
    alt="icon1"
    style={{ width: '30px', height: '30px', marginRight: '10px' }}
  />
  {selectedCategory
    ? selectedCategory
        .split('|') // แยกหมวดหมู่ด้วย '|'
        .map(item =>
          item
            .replace(/^liked$/, 'ถูกใจ') // แทนที่ "liked" ด้วย "ถูกใจ"
            .replace(/^\d+-/, '') // ลบตัวเลขนำหน้าออกจากแต่ละหมวดหมู่
        )
        .join(' | ') // รวมคำกลับมาเป็นข้อความที่คั่นด้วย '|'
    : 'ทั้งหมด'}
</div>


            <Row className="mt-5 gx-0 gy-1">

              {/* แสดงสินค้า */}
              {searchedProducts.length === 0 ? (
                <Col xs={12} className="text-center">
                  <p style={{ color: 'white', backgroundColor: '#FF8C00', padding: '10px', borderRadius: '5px' }}>
                    ไม่มีสินค้าตามหมวดหมู่ที่เลือกหรือคำค้นหาของคุณ
                  </p>
                </Col>
              ) : (
                searchedProducts.map(product => (
                  <Col key={product._id} xs={12} sm={6} md={4} lg={3} className="px-1">
  <div
    className="p-2"
    style={{
      backgroundColor: '#FFCC66',
      boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
      borderRadius: '5px',
      paddingBottom: '10px',
      marginBottom: '0px',
      marginTop: '0px',
    }}
    onClick={() => handleShowModal(product)} // เปิด Modal เมื่อคลิกที่ภาพ
  >
    <h5 style={{ fontSize: '20px', color: 'white', marginBottom: '10px', textAlign: 'center' }}>
      {product.name}
    </h5>

    <img
      src={product.imageUrl}
      alt={product.name}
      style={{
        width: '100%',
        height: '175px',
        borderRadius: '5px',
        objectFit: 'cover',
        display: 'block',
        margin: '0 auto',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      }}
    />

<div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
  <p
    style={{
      textAlign: 'left',
      paddingLeft: '7px',
      backgroundColor: '#FF8C00',
      color: 'white',
      padding: '8px 12px',
      borderRadius: '10px',
      fontWeight: 'bold',
      fontSize: '14px',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
      transition: 'all 0.3s ease',
    }}
    onMouseEnter={(e) => e.target.style.boxShadow = '0px 8px 15px rgba(0, 0, 0, 0.2)'}
    onMouseLeave={(e) => e.target.style.boxShadow = '0px 4px 10px rgba(0, 0, 0, 0.1)'}
  >
    <strong>ชุบ:</strong> {product.coating}
  </p>

  <p
    style={{
      textAlign: 'left',
      paddingLeft: '7px',
      backgroundColor: '#FF8C00',
      color: 'white',
      padding: '8px 12px',
      borderRadius: '10px',
      fontWeight: 'bold',
      fontSize: '14px',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
      transition: 'all 0.3s ease',
      marginTop: '-10px', // ให้ห่างจากบรรทัดก่อนหน้า
      marginBottom:'0px',
    }}
    onMouseEnter={(e) => e.target.style.boxShadow = '0px 8px 15px rgba(0, 0, 0, 0.2)'}
    onMouseLeave={(e) => e.target.style.boxShadow = '0px 4px 10px rgba(0, 0, 0, 0.1)'}
  >
    <strong>วัสดุ:</strong> {product.material}
  </p>
</div>


  </div>
</Col>

                ))
              )}
            </Row>
          </div>
        </Col>
      </Row>

     {/* Modal สำหรับแสดงรายละเอียดสินค้า */}
   {/* Modal สำหรับแสดงรายละเอียดสินค้า */}
<Modal 
  show={showModal && selectedProduct !== null}  // ให้ Modal แสดงเมื่อมีข้อมูล selectedProduct
  onHide={handleCloseModal} 
  backdrop="static"  // เพื่อให้ไม่สามารถคลิกที่พื้นที่นอก Modal เพื่อปิด
  keyboard={false}  // ไม่ให้กด Escape เพื่อปิด
  centered
>
  <Modal.Header style={{ backgroundColor: '#FF9900', color: 'white', borderColor: '#ff66000',border:'0px' }}>
    <Modal.Title>
      {selectedProduct
        ? selectedProduct.category
            .replace(/^\d+-/, '') // ลบเลขนำหน้าออกจากหมวดหมู่
        : ''}
    </Modal.Title>
  </Modal.Header>

  <Modal.Body style={{ backgroundColor: '#FFCC66' }}>
    {selectedProduct && (
      <div>
        
        <h5 style={{ 
        fontWeight: 'bold', 
        color: 'white', 
        backgroundColor: '#FF9900', 
        border: '5px solid #FF9900', 
        borderRadius: '5px',
        display: 'block', // ทำให้ element จัดกึ่งกลางได้
        width: 'fit-content', // ให้ขนาดตามข้อความ
        padding: '5px 10px',
        margin: '0 auto', // จัดให้อยู่ตรงกลาง
        marginBottom:'20px',
      }}>
          {selectedProduct.name}
        </h5>

        <div style={{ textAlign: 'center' }}>
      <img
        src={selectedProduct.imageUrl}
        alt={selectedProduct.name}
        style={{
          width: '100%',
          maxHeight: '500px',
          objectFit: 'contain',
          borderRadius: '10px',
          boxShadow: '0 10px 20px rgba(0, 0, 0, 0.3)',
          marginBottom: '15px',
          cursor: 'pointer',
          transition: 'transform 0.3s ease', // การเปลี่ยนแปลงที่นุ่มนวล
          transform: isExpanded ? 'scale(1.5)' : 'scale(1)', // ขยายภาพเมื่อคลิก
        }}
        onClick={handleImageClick} // เรียกฟังก์ชันเมื่อคลิก
      />
    </div>

        <div
          style={{
            marginTop: '15px',
            display: 'flex',
            justifyContent: 'center', // จัดให้อยู่ตรงกลาง
            alignItems: 'center', // จัดให้อยู่แนวเดียวกัน
            gap: '15px',
          }}
        >
          <div
            style={{
              flex: 1,
              maxWidth: '250px',
              padding: '15px',
              backgroundColor: '#FF9933	',
              border: '2px solid 	#FF9900',
              borderRadius: '4px',
              fontFamily: 'Arial, sans-serif',
              fontSize: '16px',
              fontWeight: 'bold',
              color: 'white',
              textAlign: 'center',
            }}
          >
            <p style={{ margin: 0 }}>วัสดุ:</p>
            <p style={{ margin: 0 }}>{selectedProduct.material}</p>
          </div>

          <div
            style={{
              flex: 1,
              maxWidth: '250px',
              padding: '15px',
              backgroundColor: '#FF9933	',
              border: '2px solid #FF9900',
              borderRadius: '4px',
              fontFamily: 'Arial, sans-serif',
              fontSize: '16px',
              fontWeight: 'bold',
              color: 'white',
              textAlign: 'center',
            }}
          >
            <p style={{ margin: 0 }}>ชุบ:</p>
            <p style={{ margin: 0 }}>{selectedProduct.coating}</p>
          </div>
        </div>
      </div>
    )}
  </Modal.Body>
  
  <Modal.Footer
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: '#FF8C00',
      borderTop: '2px solid #FF9933',
      padding: '10px',
    }}
  >
    {/* ปุ่ม ถูกใจ */}
    <Button
      variant={likedProducts.includes(selectedProduct?._id) ? 'success' : 'primary'}
      onClick={() => handleLike(selectedProduct?._id)}
      style={{
        backgroundColor: likedProducts.includes(selectedProduct?._id) ? '#2E8B57' : '#FF9933',
        borderColor: likedProducts.includes(selectedProduct?._id) ? '#FF9900' : '#FF9900',
        color: 'white',
        fontWeight: 'bold',
        padding: '10px 20px',
        flex: 1, // ทำให้ขนาดของปุ่มเท่ากัน
        marginRight: '10px',
        minWidth: '120px', // ตั้งขนาดขั้นต่ำของปุ่ม
        borderRadius: '6px',
        boxSizing: 'border-box',
        transition: 'background-color 0.3s ease',
      }}
      onMouseEnter={(e) => {
        e.target.style.backgroundColor = likedProducts.includes(selectedProduct?._id) ? '#256D45' : '#FF8800';
      }}
      onMouseLeave={(e) => {
        e.target.style.backgroundColor = likedProducts.includes(selectedProduct?._id) ? '#2E8B57' : '#FF9933';
      }}
    >
      {likedProducts.includes(selectedProduct?._id) ? 'ถูกใจแล้ว' : 'ถูกใจ'}
    </Button>

    {/* เส้นคั่น */}
    <div
      style={{
        height: '40px',
        width: '2px',
        backgroundColor: 'white',
      }}
    ></div>

    {/* ปุ่ม ปิด */}
    <Button
      variant="secondary"
      onClick={handleCloseModal}
      style={{
        backgroundColor: '#FF9933',
        borderColor: '#FF9900',
        color: 'white',
        fontWeight: 'bold',
        padding: '10px 20px',
        flex: 1, // ทำให้ขนาดของปุ่มเท่ากัน
        marginLeft: '10px',
        minWidth: '120px', // ตั้งขนาดขั้นต่ำของปุ่ม
        borderRadius: '6px',
        boxSizing: 'border-box',
        transition: 'background-color 0.3s ease',
      }}
      onMouseEnter={(e) => {
        e.target.style.backgroundColor = '#FF8800';
      }}
      onMouseLeave={(e) => {
        e.target.style.backgroundColor = '#FF9933';
      }}
    >
      ปิด
    </Button>
  </Modal.Footer>
</Modal>

</Container>

  );
};

export default Dashboard;